import React from "react";

import PropTypes from "prop-types";
import { Button, CircularProgress, Tooltip } from "@mui/material";

const LOADING_SPINNER = <CircularProgress color="inherit" size={24} />;

const CustomButton = ({
  className = "",
  buttonType = "fill",
  fillDark = false,
  disabled = false,
  label = "",
  onClick = () => {},
  onKeyPress = () => {},
  isLoading = false,
  href,
  type = "button",
  disabledTooltip = "This action is not available",
}) => {
  const button = (
    <Button
      type={type}
      disabled={disabled}
      target="_blank"
      className={`${className} custom-button-${buttonType}${
        fillDark ? "-dark" : ""
      }`}
      href={href}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {isLoading ? LOADING_SPINNER : label}
    </Button>
  );

  return disabled ? (
    <Tooltip title={disabledTooltip} arrow placement="top">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

const CustomOutlineButton = ({
  className = "",
  label = "",
  onClick = () => {},
  onKeyPress = () => {},
  href = "",
  disabled = false,
  isLoading = false,
  buttonType = "dark",
  startIcon,
  variant = "text",
  disabledTooltip = "This action is not available",
}) => {
  const button = (
    <Button
      disabled={disabled}
      target="_blank"
      className={`${className} custom-button-${buttonType}`}
      href={href}
      onClick={onClick}
      onKeyPress={onKeyPress}
      startIcon={startIcon}
      variant={variant}
    >
      {isLoading ? LOADING_SPINNER : label}
    </Button>
  );

  return disabled ? (
    <Tooltip title={disabledTooltip} arrow placement="top">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

const CustomButtonWithIcon = ({
  label = "",
  onClick = () => {},
  onKeyPress = () => {},
  href = "",
  disabled = false,
  isLoading = false,
  buttonType = "dark",
  icon,
  disabledTooltip = "This action is not available",
}) => {
  const button = (
    <Button
      disabled={disabled}
      target="_blank"
      className={`custom-button-${buttonType}`}
      href={href}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {isLoading ? LOADING_SPINNER : label}
      <div className="custom-button__icon">{icon}</div>
    </Button>
  );

  return disabled ? (
    <Tooltip title={disabledTooltip} arrow placement="top">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

CustomButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabledTooltip: PropTypes.string,
};

CustomOutlineButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabledTooltip: PropTypes.string,
};

CustomButtonWithIcon.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabledTooltip: PropTypes.string,
};

export { CustomOutlineButton, CustomButtonWithIcon };
export default CustomButton;
